body {
    background: #eaeaea;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Montserrat', sans-serif;
}
.minvin {
    width: 400px;
}
.minvin h1 {
    color: #58181F;
    font-size: 100px;
    margin: 0 0 0 -8px;
}
.minvin h2 {
    margin: 20px 0 10px 0;
}
.minvin p {
    margin: 5px 0;
}
.minvin .ejes-af {
    margin-top: 50px;
    font-weight: 700;
}