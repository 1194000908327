* {
  color: #333;  
}
.section {
    background: #eaeaea;
    font-family: 'Montserrat', sans-serif;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.section h1 {
    font-weight: 400;
    margin: 0;
}
.section h2 {
    margin-top: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: #333;
    /* font-size: 22px; */
}
.section .list-item {
    display: flex;
    align-items: center;
    gap: 20px;
}
.section .list-item-icon {
    font-size: 20px;
}
.avatar-container {
    padding: 70px;
}
.avatar-border {
    border-radius: 200px;
    padding: 10px 8px 6px 8px;
    background: linear-gradient(132deg, rgba(203,158,57,1) 0%, rgba(158,40,141,1) 75%, rgba(62,89,149,1) 100%);
    box-shadow: 0 5px 20px rgba(0,0,0,0.3);
}
.avatar {
    height: 250px;
    width: 250px;
    border-radius: 200px;
    border: 5px solid #eaeaea;
}
.primaryBtn {
    border-radius: 100px;
    padding: 10px;
    background: #eaeaea;
    width: 200px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(132deg, rgba(203,158,57,1) 0%, rgba(158,40,141,1) 75%, rgba(62,89,149,1) 100%);
}
.primaryBtn:hover {
    background: linear-gradient(132deg, rgba(203,158,57,1) 0%, rgba(158,40,141,1) 75%, rgba(62,89,149,1) 100%);
    color: #fff;
}
/* MOBILE */
@media screen and (max-width:769px) {
    .section {
        padding: 20px;
        height: 100%;
        gap: 50px;
    }
    .avatar-container {
        padding: 0;
    }
    h1 {
        font-size: 20px;
    }
    h2 {
        font-size: 18px;
    }
}